import {
  BundleArgs,
  CsrBundle,
  csrBundle,
  defineFeatureServices,
} from '@oneaudi/feature-services';
import {
  FeatureServiceProviderDefinition,
  FeatureServices,
  Logger,
  SharedFeatureService,
} from '@feature-hub/core';
import {
  getAuthOptions,
  getCarlineLinkServiceConfig,
  getColaOptions,
  getConfiguratorLinkOptions,
  getCookieServiceOption,
  getCurrentCarlineOptions,
  getDisclaimerManagerOptions,
  getEnvConfigOptions,
  getGfaServiceConfig,
  getI18nOptions,
  getLocaleOptions,
  getMarketContextServiceOptions,
  getNavigationOptions,
  getNumberFormatterOptions,
  getOneGraphOptions,
  getRenderModeOptions,
  getStateManagerConfig,
  getTrackingOptions,
  getVueFormatterOptions,
  prepareWindow,
} from './service-configuration';

import { ContentStore } from '@oneaudi/content-service';
import { createLogger } from '../logger';
import { rootLocationTransformer } from './service-configuration-history';

export type FeatureServiceConfigOverride = Partial<BundleArgs<CsrBundle>>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare global {
  interface Window {
    SETUPS: {
      get(name: string): string;
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    featureServiceDefinitions: Array<FeatureServiceProviderDefinition<any>>;
    featureServiceConfigs?: FeatureServiceConfigOverride;
    digitalData: Record<string, unknown>;
    audiIntegrator?: {
      printFeatureHubInfo?(): void;
    };
  }
}

export const createFeatureServiceDefinitions = (
  contentStore: ContentStore,
  logger: Logger,
): FeatureServiceProviderDefinition<
  SharedFeatureService,
  FeatureServices
>[] => {
  prepareWindow();

  const serviceArgs: BundleArgs<CsrBundle> = {
    'audi:configuratorInPageNavigation': [],
    'audi:configuratorStateManager': [getStateManagerConfig(document.head)],
    'audi:envConfigService': [getEnvConfigOptions(document.head)],
    'audi-audicode-service': [],
    'audi-carline-link-service': [getCarlineLinkServiceConfig(document.head)],
    'audi-cola-query-service': [],
    'audi-cola-service': [getColaOptions(document.head)],
    'audi-configurator-data-service': [],
    'audi-configurator-footnote-service': [],
    'audi-configurator-link-service': [
      getConfiguratorLinkOptions(document.head),
    ],
    'audi-content-service': [contentStore],
    'audi-current-carline-service': [getCurrentCarlineOptions(document.head)],
    'audi-dynamic-footnote-service': [],
    'audi-footnote-reference-service': [],
    'audi-market-context-service': [
      getMarketContextServiceOptions(document.head),
    ],
    'audi-number-formatter-service': [getNumberFormatterOptions(document.head)],
    'audi-personalization-service': [],
    'audi-render-mode-service': [getRenderModeOptions()],
    'audi-tracking-service': [getTrackingOptions()],
    'cookie-service': [getCookieServiceOption()],
    'dbad:audi-i18n-service': [getI18nOptions(document.head)],
    'dbad:audi-vue-formatter-service': [getVueFormatterOptions(document.head)],
    'disclaimer-manager': [getDisclaimerManagerOptions()],
    'gfa:cart-service': [{}],
    'gfa:service-config-provider': [getGfaServiceConfig()],
    'locale-service': [getLocaleOptions(document.head)],
    'navigation-service': [getNavigationOptions()],
    'onegraph-query-service': [],
    'onegraph-service': [getOneGraphOptions(document.head)],
    'oneshop:apollo-service': [{}],
    'oneshop:tenant-service': [{}],
    'oneshop:user-session-service': [{}],
    's2:history': [rootLocationTransformer],
    's2:logger': [createLogger],
    'user-vehicle-feature-service': [],
    'vw:authService': [getAuthOptions(document.head)],
    ...window.featureServiceConfigs,
  };

  const serviceDefinitions = defineFeatureServices(
    csrBundle,
    serviceArgs,
    logger,
  );

  return [...serviceDefinitions, ...window.featureServiceDefinitions];
};
